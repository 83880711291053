import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './Home.sass';

function Home() {
    return (
        <div className='home'>
            <Container className='home__content'>
                <Row className='home__section'>
                    <Col lg={12}>
                        <h1>VAMANOS</h1>
                        <h2>Valencia Association of Merchants, Artists, Neighbors, and Organizations</h2>
                        <Image className='home__image' src='https://vamanossf.s3.us-west-1.amazonaws.com/ghost-valencia.jpg' alt='Valencia Ghost Town' />
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={12}>
                        <h2 className='home__uppercase'>Let's rise up together to save San Francisco!!</h2>
                        <p>Valencia Association of Merchants, Artists, Neighbors, and Organizations (VAMANOS) was started by a group of Valencia merchants fed up with SFMTA’s overreach, feigned community engagement, and lack of accountability.</p>
                        <p>We are stronger together. VAMANOS is here to give you a voice in response to negligent city policies and leaders whose disregard for community input and impact goes unchecked. VAMANOS started on Valencia, but the need for change has been heard all across the city. We are here for all San Franciscans.</p>
                        <div className='home__button-container'>
                            <a
                                aria-label='Join Vamanos in a new tab'
                                href='https://forms.gle/9FyeXvMNBN2xt5MH9'
                                target='_blank'
                            >
                                <Button variant='success'>Join VAMANOS</Button>
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={12}>
                        <h2>Are you ready for change?</h2>
                        <p>VAMANOS’ first measure of business is to call for the immediate resignation or termination of Jeff Tumlin as the Director of SFMTA based on a series of failures and inadequacies in his management of critical transportation projects, including but not limited to: the Valencia Center Running Bike Lane, Geary Boulevard BRT, L Taraval Improvement Project, Frida Kahlo Way Bike Lane, and West Portal Safety Plan, among many other instances of planning failures by our Municipal Transit Agency.</p>
                        <p>Enough with the weaponization of tragedies like West Portal and well-meaning policies like Vision Zero, wielded to drastically change the cityscape to fit the vision of an unelected few at the cost of not only our day-to-day lived experience, but the culture and vitality of our city.</p>
                        <p>Join the others clamoring for change and sign the petition to remove Jeffrey Tumlin.</p>
                        <div className='home__button-container'>
                            <a
                                aria-label='Join Vamanos in a new tab'
                                href='https://www.change.org/p/sfmta-director-jeff-tumlin-must-resign'
                                target='_blank'
                            >
                                <Button variant='success'>Sign the Petition</Button>
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={12}>
                        <h2>The New Valencia Ghost Town</h2>
                        <h3>Empowering communities for positive change</h3>
                        <div className='home__grid'>
                            <Image className='home__grid-image' src='https://vamanossf.s3.us-west-1.amazonaws.com/img_4686.jpg' alt="Valencia Ghost Town" />
                            <Image className='home__grid-image' src='https://vamanossf.s3.us-west-1.amazonaws.com/img_4695.jpg' alt="Valencia Ghost Town" />
                            <Image className='home__grid-image' src='https://vamanossf.s3.us-west-1.amazonaws.com/img_4699.jpg' alt="Valencia Ghost Town" />
                            <Image className='home__grid-image' src='https://vamanossf.s3.us-west-1.amazonaws.com/img_4702.jpg' alt="Valencia Ghost Town" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Home;
